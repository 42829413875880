import { render, staticRenderFns } from "./StreamingWidget.vue?vue&type=template&id=d899630c&scoped=true"
import script from "./StreamingWidget.vue?vue&type=script&lang=js"
export * from "./StreamingWidget.vue?vue&type=script&lang=js"
import style0 from "./StreamingWidget.vue?vue&type=style&index=0&id=d899630c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d899630c",
  null
  
)

export default component.exports